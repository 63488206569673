<template>
  <div class="creatorFileCard" v-if="file">
    <SingleCard class="user-files-file" :style="`--cover:url('${encode_url(getFileCover(file))}')`">
      <div class="cover" @click="viewFile(file)"></div>
      <div class="filePriceInfo">
        <p>{{ $locale[file.availableFor] }}</p>
        <div class="priceFile" v-if="hasPrice">
          <span class="icon"><iconic name="lock1a" /></span>
          <span class="number">{{ file.price }}</span>
        </div>
      </div>
      <div class="options">
        <a class="options-button primary-circle __filled" @click="fileMenu($event, file)"><iconic name="ellipsis-v" /></a>
      </div>
    </SingleCard>
  </div>
</template>

<script>
export default {
  props: ["file"],
  components: {
    UserFilesCard: () => import("../user/UserFilesListCard.vue"),
  },
  methods: {
    fileMenu: function($event, file) {
      this.$store.state.user.fileMenu = { event: $event, file: file };
    },
    viewFile: function({ file }) {
      this.mediaView(file);
    },
  },
  computed: {
    hasPrice: function() {
      const has = this.file && this.file.availableFor === "purchase";
      return has;
    },
  },
};
</script>

<style lang="scss" scoped>
.creatorFileCard {
  position: relative;
  .cover {
    cursor: pointer;
  }
  .filePriceInfo {
    display: flex;
    justify-content: space-between;
    padding: $mpadding/2;
  }
  .priceFile {
    display: flex;
    align-items: center;
    gap: $mpadding/2;
    color: $primary_color;
  }
  .iconic {
    @include iconic();
  }
  .number {
    font-weight: bold;
  }
}
</style>
